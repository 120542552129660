<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.png')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.png')} )` }"
    >
      <div class="w1300">

        <!-- sub header -->
        <div id="apply_header">
          <div class="apply_header_left">
            <h3 class="apply_header_title txt-bold">招募對象</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
            <div class="apply_header_content">
              <p>凡認同本學會之章程，依據規章，遵守規則，申請加入的<br>
                <span>台灣居家醫療相關實務工作者</span><br>
                <span>從事居家醫療相關研究者</span><br>
                <span>醫師 (領有醫師證書滿2年)</span>
              </p>
            </div>
          </div>
          <div class="apply_header_right">
            <h3 class="apply_header_title txt-bold">入會費用</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
            <div class="apply_header_content">
              <p>
                入會費 (2000元) 常年會費 (3000元/年，250元/月)<br>
                例如:8月3日申請入會，將收入會費2000元 + 9-12月常年會費1000元，共3000元。<br>
                付款採用 線上信用卡
                <!-- <template> / ATM 轉帳 / 超商代碼。</template> -->
                繳費<br>
                如有任何使用上的疑慮，歡迎您的來電 04-24360305 謝謝。
              </p>
            </div>
          </div>
        </div>

        <ApplicationStage @toggleVerifyModal="toggleVerifyModal" />

      </div>
    </section>

    <main>
      <section id="application_apply">
        <div class="w1000">

          <!-- form area -->
          <div class="form_box">
            <form id="form_application_apply" enctype="multipart/form-data">

              <div class="form_interactive">
                <div class="half">

                  <TextInput
                    class="w50"
                    v-model.trim="user.realname"
                    :item="inputFormat.nameInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                      val => val.length > 1 || '長度錯誤!',
                    ]"
                  />

                  <Radio
                    class="w50"
                    v-model="user.gender"
                    :item="inputFormat.genderRadio"
                  />

                </div>
                <div class="half">
                  <label for="birthday" class="w100">
                    <p><span class="txt-red txt-regular">*</span>出生年月日</p>
                    <date-pick class="w100" v-model="user.birthday" :format="'YYYY-MM-DD'">
                    </date-pick>
                  </label>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.id_number"
                  :item="inputFormat.idInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                  ]"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.phone"
                  :item="inputFormat.phoneInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.memo.office_tel"
                  :item="inputFormat.officeTelInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.tel"
                  :item="inputFormat.telInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.email"
                  :item="inputFormat.emailInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
                    '信箱格式錯誤'],
                  ]"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.line_id"
                  :item="inputFormat.lineIdInput"
                />

                <div class="select_container w100">
                  <p><span class="txt-red txt-regular">*</span>戶籍地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ homeAddressErrorMsg }}</span>
                    <select
                      v-model="user.city"
                      class="select_city address_top w33"
                      @change="resetHomeSelect"
                    >
                      <option value="" disabled selected>請選擇</option>
                      <option
                        v-for="(town, index) in homeTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.area"
                      class="select_city address_top w33"
                      @change="updateHomeZip"
                    >
                      <option value="" disabled selected>請選擇</option>
                      <option
                        v-for="(area, index) in homeAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.zipcode"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.addr"
                      :item="inputFormat.addressHomeDetailInput"
                      :rules="[
                        val => !!val || '必填欄位！',
                      ]"
                    />
                  </div>
                </div>

                <TextInput
                  class="w50"
                  v-model.trim="user.jobs.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.hospital_id"
                  :item="inputFormat.hospitalIdInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <div class="select_container w100">
                  <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                    <select
                      v-model="user.memo.hospitalCity"
                      class="select_city address_top w33"
                      @change="resetHospitalSelect"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(town, index) in hospitalTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.memo.hospitalArea"
                      class="select_city address_top w33"
                      @change="updateHospitalZip"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(area, index) in hospitalAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.memo.hospitalZipcode"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.memo.hospitalAddr"
                      :item="inputFormat.addressHospitalDetailInput"
                    />
                  </div>
                </div>

                <TextInput
                  class="w100"
                  v-model.trim="user.memo.resume"
                  :item="inputFormat.resumeInput"
                />

                <TextInput
                  class="w50"
                  v-model.trim="user.school.name"
                  :item="inputFormat.schoolNameInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />

                <div class="select_container w50">
                  <TextInput
                    class="w50"
                    v-model.trim="user.school.graduation"
                    :item="inputFormat.schoolGraduationInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                    ]"
                  />
                  <Select
                    class="w50"
                    v-model="user.memo.license_to_date"
                    :item="inputFormat.licenseToDateSelect"
                    :rules="[
                      val => !!val || '必選欄位！',
                    ]"
                  />
                </div>

                <Upload
                  class="w50"
                  v-model="user.files.id_1"
                  :item="inputFormat.id1_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />

                <Upload
                  class="w50"
                  v-model="user.files.id_2"
                  :item="inputFormat.id2_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />

                <Upload
                  class="w50"
                  v-model="user.files.license_1"
                  :item="inputFormat.license1_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />

                <Upload
                  class="w50"
                  v-model="user.files.license_2"
                  :item="inputFormat.license2_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />

                <Upload
                  class="w100"
                  v-model="user.files.photo"
                  :item="inputFormat.photo_upload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />

                <TextInput
                  class="w100"
                  v-model.trim="user.memo.fee_title"
                  :item="inputFormat.feeTitleInput"
                />
              </div>

              <div class="form_submit">
                <Checkbox
                  v-model="user.memo.agreeToFollow"
                  :item="inputFormat.agreeToFollowCheckbox"
                />
                <button
                  type="button"
                  id="send_btn"
                  class="btn"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>

    <!-- lightbox -->
    <div
      id="modal_container-verify"
      class="modal_container"
      :class="{ active: modalVerify }"
      @click="closeModal"
    >
      <div class="modal" @click.stop>
        <div class="close_btn h2" @click="closeModal"><span>×</span></div>
        <form action="" method="post" @submit.prevent>
          <ModalTextInput
            v-model.trim="userVerify.id_number"
            :item="inputFormat.idVerifyInput"
            :rules="[
              [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤'],
            ]"
            :maxlength="10"
          />
          <button
            id="send_btn_verify"
            class="btn"
            type="button"
            @click="verifyModalSubmit"
          >
            送出
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import cities from '@/data/cities.json';

import ApplicationStage from '@/components/ApplicationStage.vue';
import TextInput from '@/components/form/TextInput.vue';
import ModalTextInput from '@/components/form/ModalTextInput.vue';
import Select from '@/components/form/Select.vue';
import Radio from '@/components/form/Radio.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import Upload from '@/components/form/Upload.vue';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

import { mapState, mapMutations, mapActions } from 'vuex';
import { registerAsMember, idVerify, login } from '@/lib/http';
import { setAxiosHeaders } from '@/boot/axios';

export default {
  title: '入會申請',
  name: 'ApplicationIndex',
  data() {
    return {
      homeAddressErrorMsg: '',
      hospitalAddressErrorMsg: '',
      modalVerify: false,
      userVerify: {
        id_number: '',
      },
      user: {
        type: 1,
        user_type: 1,
        id_number: '',
        realname: '',
        gender: '',
        birthday: '1970-01-01',
        phone: '',
        tel: '',
        memo: {
          office_tel: '',
          line_id: '',
          hospitalCity: '',
          hospitalArea: '',
          hospitalZipcode: '',
          hospitalAddr: '',
          resume: '',
          license_to_date: '',
          fee_title: '',
          agreeToFollow: '',
        },
        email: '',
        city: '',
        area: '',
        zipcode: '',
        addr: '',
        jobs: {
          department: '',
        },
        hospital_id: '',
        school: {
          name: '',
          graduation: '',
        },
        files: {
          id_1: '',
          id_2: '',
          license_1: '',
          license_2: '',
          photo: '',
        },
      },
      fakeUser: {
        type: 1,
        user_type: 1,
        id_number: 'A123456789',
        realname: '王小明',
        gender: 'm',
        birthday: '1970-01-01',
        phone: '0987654321',
        tel: '0212345678',
        memo: {
          office_tel: '0212345678',
          line_id: 'asdf1234',
          hospitalCity: '台北市',
          hospitalArea: '大安區',
          hospitalZipcode: '106',
          hospitalAddr: 'test addr',
          resume: 'sdfuiausfoijas',
          license_to_date: '11-20年',
          fee_title: 'my title',
          agreeToFollow: 'true',
        },
        email: 'test@gmail.com',
        city: '台北市',
        area: '大安區',
        zipcode: '106',
        addr: 'test addr',
        jobs: {
          department: '中醫科',
        },
        hospital_id: '台大醫院',
        school: {
          name: '台灣大學',
          graduation: '80',
        },
        files: {
          id_1: '',
          id_2: '',
          license_1: '',
          license_2: '',
          photo: '',
        },
      },
      inputFormat: {
        nameInput: {
          label: '姓名',
          type: 'text',
          placeholder: '請輸入姓名',
          required: true,
        },
        idInput: {
          label: '身分證字號',
          type: 'text',
          placeholder: '請輸入身分證字號',
          required: true,
        },
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        officeTelInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        telInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        lineIdInput: {
          label: 'LINE ID',
          type: 'text',
          placeholder: '請輸入LINE ID',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalIdInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        resumeInput: {
          label: '簡歷',
          type: 'text',
          placeholder: '請輸入職業簡歷',
          required: false,
        },
        schoolNameInput: {
          label: '畢業學校(含科系所)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        schoolGraduationInput: {
          label: '畢業年度(民國)',
          type: 'text',
          placeholder: '80',
          required: true,
        },
        licenseToDateSelect: {
          label: '取得醫師證書，至今幾年',
          placeHolder: '選擇區間',
          options: [
            '0-10年',
            '11-20年',
            '21-30年',
          ],
          required: true,
        },
        feeTitleInput: {
          label: '收據抬頭',
          type: 'text',
          placeholder: '請輸入抬頭',
          required: false,
        },
        addressHomeDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫戶籍地址',
          required: true,
        },
        addressHospitalDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: false,
        },
        genderRadio: {
          label: '性別',
          name: 'gender',
          required: true,
          options: [
            {
              name: '男',
              value: 'm',
            },
            {
              name: '女',
              value: 'f',
            },
          ],
        },
        id1_upload: {
          label: '身分證-正面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        id2_upload: {
          label: '身分證-反面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        license1_upload: {
          label: '醫師證書-正面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        license2_upload: {
          label: '醫師證書-反面',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        photo_upload: {
          label: '一年內二吋半身照片兩張',
          reminder: '格式 jpg/png/pdf。限制大小：1MB',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        agreeToFollowCheckbox: {
          routeUrl: '/about/association',
        },
        idVerifyInput: {
          label: '請輸入您的身分證字號',
          type: 'text',
          placeholder: 'A123456789',
          required: false,
        },
      },
    };
  },
  created() {
    // this.user = this.fakeUser;
  },
  computed: {
    ...mapState([
      'applyStatus',
    ]),
    homeTowns() {
      return cities.map((city) => city.name);
    },
    homeAreas() {
      const homeTownIndex = this.homeTowns.indexOf(this.user.city);
      if (homeTownIndex >= 0) {
        return cities[homeTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.memo.hospitalCity);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
    ]),
    ...mapActions([
      'login',
      'logout',
    ]),
    toggleVerifyModal() {
      this.modalVerify = !this.modalVerify;
    },
    closeModal() {
      this.modalVerify = false;
    },
    updateHomeZip() {
      const homeTownIndex = this.homeTowns.indexOf(this.user.city);
      const homeAreaIndex = this.homeAreas.indexOf(this.user.area);
      if (homeAreaIndex >= 0) {
        this.user.zipcode = cities[homeTownIndex].areas[homeAreaIndex].zip;
      }
      if (homeAreaIndex === -1) {
        this.user.zipcode = '';
      }
      this.addressHomeTest();
    },
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.memo.hospitalCity);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.memo.hospitalArea);
      if (hospitalAreaIndex >= 0) {
        this.user.memo.hospitalZipcode = cities[hospitalTownIndex].areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.user.memo.hospitalZipcode = '';
      }
      // this.addressHospitalTest();
    },
    resetHomeSelect() {
      this.user.area = '';
      this.user.zipcode = '';
      this.addressHomeTest();
    },
    resetHospitalSelect() {
      this.user.memo.hospitalArea = '';
      this.user.memo.hospitalZipcode = '';
      // this.addressHospitalTest();
    },
    addressHomeTest() {
      if (!this.user.city || !this.user.area) {
        this.homeAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
      }
      if (this.user.city && this.user.area) {
        this.homeAddressErrorMsg = '';
      }
    },
    // addressHospitalTest() {
    //   if (!this.user.memo.hospitalCity || !this.user.memo.hospitalArea) {
    //     this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.memo.hospitalCity && this.user.memo.hospitalArea) {
    //     this.hospitalAddressErrorMsg = '';
    //   }
    // },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      this.addressHomeTest();
      if (this.homeAddressErrorMsg !== '') {
        errorList.push(true);
      }
      if (errorList.indexOf(true) === -1) {
        registerAsMember(this.user).then((result) => {
          if (result.data.status) {
            login({
              id_number: this.user.id_number,
              password: this.user.id_number,
            }).then((res) => {
              this.login(res.data.result.user);
              setAxiosHeaders(this.$cookies.get('token'));
            });
            this.updateModalInfoContent('已收到您的入會申請<br><br>請至 <span class="txt-light_green">審核狀態</span> 查詢審核結果');
            this.toggleModalInfo(true);
          } else {
            this.updateModalInfoContent(result.data.message);
            this.toggleModalInfo(true);
          }
        });
      } else {
        this.updateModalInfoContent('必填欄位尚未完成！');
        this.toggleModalInfo(true);
      }
    },
    verifyModalSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Modal/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        /** 查詢時，先將目前使用者登出 */
        this.logout().then(() => {
          setAxiosHeaders(null);
        });

        /** 確認使用者資訊並導向不同頁面 */
        idVerify(this.userVerify).then((result) => {
          if (result) {
            /** 本會會員(專科/一般): 自行前往登入頁面輸入帳號密碼登入 */
            if (parseInt(result.level, 10) === 1 || parseInt(result.level, 10) === 2) {
              this.$cookies.set('realname', result.realname);
              this.$router.push({ path: '/application/success' });

            /** 非會員醫師: 預先做非會員登入，方便後續頁面資料顯示 */
            } else if (parseInt(result.level, 10) === 3) {
              login({
                id_number: this.userVerify.id_number,
                password: this.userVerify.id_number,
              }).then((res) => {
                this.login(res.data.result.user);
                setAxiosHeaders(this.$cookies.get('token'));

                /** 審核通過前往付款 */
                if (parseInt(result.apply, 10) === 2) {
                  this.$router.push({ path: '/application/payment' });
                /** 審核中 或 拒絕入會 */
                } else {
                  this.$router.push({ path: '/application/verify' });
                }
              });

            /** 非會員護理人員 */
            } else if (parseInt(result.level, 10) === 4) {
              login({
                id_number: this.userVerify.id_number,
                password: this.userVerify.id_number,
              }).then((res) => {
                this.login(res.data.result.user);
                setAxiosHeaders(this.$cookies.get('token'));
              });
              this.updateModalInfoContent('您尚未申請入會');
              this.toggleModalInfo(true);

            /** 其他 */
            } else {
              this.updateModalInfoContent('您尚未申請入會');
              this.toggleModalInfo(true);
            }
          } else {
            this.updateModalInfoContent('您尚未申請入會');
            this.toggleModalInfo(true);
          }
        });
      }
    },
  },
  components: {
    ApplicationStage,
    TextInput,
    ModalTextInput,
    Select,
    Radio,
    Checkbox,
    Upload,
    DatePick,
  },
};
</script>
